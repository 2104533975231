const BaseTheme = {
  colors: {
    transparent: 'transparent',
    white: '#fff',
    black: '#000',
    disabled: '#333',
    codGray: '#1d1d1d',
    silver: '#b8b8b8',
    mercury: '#eaeaea',
    dustyGray: '#979797',
    scooter: '#3da5d9',
    mercuryAlt: '#e8e8e8',
    gallery: '#efefef',
    doveGray: '#6d6d6d',
    tuna: '#403f4c',
    alto: '#DEDEDE',
    alto2: '#D8D8D8',
    dustyGray2: '#9B9B9B',
    red: '#D75A4A'
  },
  breakpoints: {
    small: '480px',
    interMedium: '720px',
    medium: '960px',
    large: '1440px',
    xlarge: '2300px'
  }
};

export default BaseTheme;
