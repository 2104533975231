import 'react-day-picker/dist/style.css';

import { format, isValid, parse } from 'date-fns';
import de from 'date-fns/locale/de';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { DayPicker } from 'react-day-picker';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { usePopper } from 'react-popper';
import styled from 'styled-components/macro';

const StyledDayPicker = styled.div`
  .rdp-day_selected {
    background-color: ${(props) => props.theme.colors.scooter};
    color: ${(props) => props.theme.colors.white};
  }

  .rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
    background-color: ${(props) => props.theme.colors.scooter};
    color: ${(props) => props.theme.colors.white};
  }
`;

const dateFormat = 'dd.MM.yyyy';

function DateInput({ onChange, value, placeholder, name, htmlID }) {
  const [selected, setSelected] = useState(value !== null ? value : undefined);
  const [inputValue, setInputValue] = useState(value !== null ? format(value, dateFormat) : '');
  const [isPopperOpen, setIsPopperOpen] = useState(false);

  const popperRef = useRef(null);
  const [popperElement, setPopperElement] = useState(null);

  const popper = usePopper(popperRef.current, popperElement, {
    placement: 'bottom-start'
  });

  const closePopper = () => {
    setIsPopperOpen(false);
  };

  const pickerOutsideClickRef = useDetectClickOutside({
    onTriggered: () => {
      if (isPopperOpen) {
        closePopper();
      }

      return true;
    }
  });

  const handleInputChange = (e) => {
    const update = e.currentTarget.value;
    setInputValue(update);
    const date = parse(e.currentTarget.value, dateFormat, new Date());

    if (isValid(date) && update.length === dateFormat.length) {
      setSelected(date);
      onChange(date);
    } else {
      setSelected(undefined);
      onChange(null);
    }
  };

  const openPopper = () => {
    setIsPopperOpen(true);
  };

  const handleDaySelect = (date) => {
    setSelected(date);
    if (date) {
      setInputValue(format(date, dateFormat));
      closePopper();
      onChange(date);
    } else {
      setInputValue('');
      onChange(null);
    }
  };

  return (
    <div ref={pickerOutsideClickRef}>
      <div ref={popperRef}>
        <input
          id={htmlID}
          name={name}
          type="text"
          placeholder={placeholder}
          value={inputValue}
          onChange={handleInputChange}
          onFocus={openPopper}
        />
      </div>
      {isPopperOpen && (
        <StyledDayPicker
          tabIndex={-1}
          style={{
            ...popper.styles.popper,
            background: '#fff',
            border: '1px solid #e4e4e4',
            zIndex: 1000,
            boxShadow: '5px 3px 9px 0px rgb(114 114 114 / 41%)'
          }}
          className="dialog-sheet"
          {...popper.attributes.popper}
          ref={setPopperElement}
          role="dialog"
        >
          <DayPicker
            locale={de}
            initialFocus={isPopperOpen}
            mode="single"
            defaultMonth={selected}
            selected={selected}
            onSelect={handleDaySelect}
          />
        </StyledDayPicker>
      )}
    </div>
  );
}

DateInput.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.any,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  htmlID: PropTypes.string
};

DateInput.defaultProps = {
  onChange: () => false,
  value: null,
  placeholder: '',
  name: '',
  htmlID: ''
};

export default DateInput;
