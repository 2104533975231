import { ReactComponent as Logo } from 'assets/images/logo.svg';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Box from './Box';
import Text from './Text';

const StyledHeader = styled(Box)`
  display: flex;
  flex-direction: column;
  position: relative;
  background: ${(props) => props.theme.colors.scooter};

  @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
    flex-direction: row;
    position: relative;
    justify-content: space-between;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.large}) {
    flex-direction: row;
    position: relative;
    justify-content: space-between;
  }
`;

const StyledTitle = styled(Box)`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: center;
  /* justify-content: flex-start; */
  align-content: center;
  align-items: center;
  padding: 10px 10px 20px 10px;

  @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.large}) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
  }
`;

const StyledActions = styled(Box)`
  width: 100px;
`;

const StyledLogo = styled(Box)`
  padding: 5px 10px 5px 20px;
  svg {
    height: 90px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
    svg {
      height: 100px;
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoints.large}) {
    svg {
      height: 100px;
    }
  }
`;

function Header({ title }) {
  return (
    <StyledHeader>
      <StyledLogo>
        <Logo />
      </StyledLogo>
      <StyledTitle>
        <Text color="white" size="2xl">
          {title}
        </Text>
      </StyledTitle>
      <StyledActions />
    </StyledHeader>
  );
}

Header.propTypes = {
  title: PropTypes.string
};

Header.defaultProps = {
  title: ''
};

export default Header;
