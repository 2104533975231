import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { faCircleInfo as InfoIcon } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Box from './Box';
import Text from './Text';

const StyledInfo = styled(Box)`
  padding: 30px;
  border: 2px solid ${(props) => props.theme.colors.scooter};
  border-radius: 8px;
  margin-bottom: 20px;
  position: relative;
`;
const StyledInfoIcon = styled(Box)`
  position: absolute;
  top: 10px;
  right: 20px;
`;

const StyledInfoText = styled(Box)``;

function InfoBox({ text }) {
  return (
    <StyledInfo>
      <StyledInfoIcon>
        <FontAwesomeIcon icon={InfoIcon} size="lg" color="#3da5d9" />
      </StyledInfoIcon>
      <StyledInfoText>
        <Text>{text}</Text>
      </StyledInfoText>
    </StyledInfo>
  );
}

InfoBox.propTypes = {
  text: PropTypes.string
};

InfoBox.defaultProps = {
  text: ''
};

export default InfoBox;
