import { Box, Button, DateInput, Label, Text } from 'components';
import UploadInput from 'components/UploadInput';
import useForm from 'hooks/useForm';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getBillingFormConfig } from 'utils/form';

const StyledInputRow = styled(Box)`
  margin-bottom: 10px;

  input,
  select {
    border: 1px solid #1d1d1d;
    display: block;
    padding: 15px;
    width: 100%;
  }

  span {
    /*
    // letter-spacing: .8px;
    // color: #1d1d1d;
    // line-height: 30px;
    */
  }
`;

const StyledTextArea = styled.textarea`
  border: 1px solid #1d1d1d;
  display: block;
  padding: 15px;
  width: 100%;
  resize: none;
`;

const StyledButtonRow = styled(Box)`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-between;

  @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
    flex-direction: row;
    gap: 10px;
    justify-content: space-between;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.large}) {
  }
`;

const StyledFormHeadline = styled(Box)`
  margin-top: 40px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  div {
    padding: 3px;
    width: 130px;
    height: 1px;
    border-bottom: 2.8px solid ${(props) => props.theme.colors.scooter};
  }
`;

function BillingForm({ onSubmit, data }) {
  const [uploadFiles, setUploadFiles] = useState([]);

  const {
    isValid,
    formData,
    updateField,
    updateData,
    updateConfig,
    isFieldValid,
    isFieldRequired,
    handleSubmit
  } = useForm({ validation: 'submit', defaultData: data });

  useEffect(() => {
    const formConfig = getBillingFormConfig();
    updateConfig(formConfig);
    updateData(data);
  }, [data]);

  const submitMail = ({ submitData, submitValid }) => {
    if (submitValid) {
      onSubmit({ data: submitData, files: uploadFiles, type: 'mail' });
    } else {
      scrollToError();
    }
  };

  const submitPreview = ({ submitData, submitValid }) => {
    if (submitValid) {
      onSubmit({ data: submitData, files: uploadFiles, type: 'preview' });
    } else {
      scrollToError();
    }
  };

  const updateBillingDate = (update) => {
    updateField('billingDate', update);
  };

  const scrollToError = () => {
    setTimeout(() => {
      const element = document.querySelector('.with-error');
      element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
    }, 250);
  };
  return (
    <>
      <StyledFormHeadline>
        <Text size="lg" bold lSpacing="3px" uppercase>
          Allgemeine Daten
        </Text>
        <div /> {/* line */}
      </StyledFormHeadline>

      <StyledInputRow>
        <Label
          htmlFor="gender"
          text="Geschlecht"
          required={isFieldRequired('gender')}
          valid={isFieldValid('gender')}
        >
          <select
            id="gender"
            name="gender"
            value={formData.gender}
            onChange={(e) => updateField('gender', e.target.value)}
          >
            <option default value="">
              - Bitte wählen -
            </option>
            <option value="male">Männlich</option>
            <option value="female">Weiblich</option>
            <option value="diverse">Divers</option>
          </select>
        </Label>
      </StyledInputRow>
      <StyledInputRow>
        <Label
          htmlFor="title"
          text="Titel"
          required={isFieldRequired('title')}
          valid={isFieldValid('title')}
        >
          <input
            id="title"
            name="title"
            type="text"
            value={formData.title}
            onChange={(e) => updateField('title', e.target.value)}
          />
        </Label>
      </StyledInputRow>
      <StyledInputRow>
        <Label
          htmlFor="firstname"
          text="Vorname"
          required={isFieldRequired('firstname')}
          valid={isFieldValid('firstname')}
        >
          <input
            id="firstname"
            name="firstname"
            type="text"
            value={formData.firstname}
            onChange={(e) => updateField('firstname', e.target.value)}
          />
        </Label>
      </StyledInputRow>

      <StyledInputRow>
        <Label
          htmlFor="lastname"
          text="Nachname"
          required={isFieldRequired('lastname')}
          valid={isFieldValid('lastname')}
        >
          <input
            id="lastname"
            name="lastname"
            type="text"
            value={formData.lastname}
            onChange={(e) => updateField('lastname', e.target.value)}
          />
        </Label>
      </StyledInputRow>
      <StyledInputRow>
        <Label
          htmlFor="email"
          text="E-Mail"
          required={isFieldRequired('email')}
          valid={isFieldValid('email')}
        >
          <input
            id="email"
            name="email"
            type="text"
            value={formData.email}
            onChange={(e) => updateField('email', e.target.value)}
          />
        </Label>
      </StyledInputRow>

      <StyledInputRow>
        <Label
          htmlFor="street"
          text="Straße & Hausnummer"
          required={isFieldRequired('street')}
          valid={isFieldValid('street')}
        >
          <input
            id="street"
            name="street"
            type="text"
            value={formData.street}
            onChange={(e) => updateField('street', e.target.value)}
          />
        </Label>
      </StyledInputRow>
      <StyledInputRow>
        <Label
          htmlFor="city"
          text="Ort"
          required={isFieldRequired('city')}
          valid={isFieldValid('city')}
        >
          <input
            id="city"
            name="city"
            type="text"
            value={formData.city}
            onChange={(e) => updateField('city', e.target.value)}
          />
        </Label>
      </StyledInputRow>
      <StyledInputRow>
        <Label
          htmlFor="zip"
          text="PLZ"
          required={isFieldRequired('zip')}
          valid={isFieldValid('zip')}
        >
          <input
            id="zip"
            name="zip"
            type="text"
            value={formData.zip}
            onChange={(e) => updateField('zip', e.target.value)}
          />
        </Label>
      </StyledInputRow>
      <StyledInputRow>
        <Label
          htmlFor="iban"
          text="IBAN"
          required={isFieldRequired('iban')}
          valid={isFieldValid('iban')}
        >
          <input
            id="iban"
            name="iban"
            type="text"
            value={formData.iban}
            onChange={(e) => updateField('iban', e.target.value)}
          />
        </Label>
      </StyledInputRow>

      <StyledInputRow>
        <Label
          htmlFor="billingDate"
          text="Rechnungsdatum"
          required={isFieldRequired('billingDate')}
          valid={isFieldValid('billingDate')}
        >
          <DateInput
            id="billingDate"
            name="billingDate"
            value={formData.billingDate}
            onChange={updateBillingDate}
            placeholder="dd.mm.yyyy"
          />
        </Label>
      </StyledInputRow>

      <StyledFormHeadline>
        <Text size="lg" bold lSpacing="3px" uppercase>
          Kostenpunkte
        </Text>
        <div />
      </StyledFormHeadline>
      <StyledInputRow>
        <Label
          htmlFor="units"
          text={
            <>
              Geleistete Unterrichtseinheiten{' '}
              <small>
                <i>(Berechnet wird der endgültige Betrag in EUR mit {data.uePrice} EUR / UE)</i>
              </small>
            </>
          }
          required={isFieldRequired('units')}
          valid={isFieldValid('units')}
        >
          <input
            id="units"
            name="units"
            type="text"
            value={formData.units}
            onChange={(e) => updateField('units', e.target.value)}
          />
        </Label>
      </StyledInputRow>

      {data.showKilometer === true ? (
        <StyledInputRow>
          <Label
            htmlFor="kilometer"
            text={
              <>
                Gefahrene Kilometer{' '}
                <small>
                  <i>(Berechnet wird der Betrag in EUR mit 0,30 EUR / km)</i>
                </small>
              </>
            }
            required={isFieldRequired('kilometer')}
            valid={isFieldValid('kilometer')}
          >
            <input
              id="kilometer"
              name="kilometer"
              type="text"
              value={formData.kilometer}
              onChange={(e) => updateField('kilometer', e.target.value)}
            />
          </Label>
        </StyledInputRow>
      ) : null}

      {data.showHotelCosts === true ? (
        <StyledInputRow>
          <Label
            htmlFor="hotelCosts"
            text="Hotelkosten in EUR"
            required={isFieldRequired('hotelCosts')}
            valid={isFieldValid('hotelCosts')}
          >
            <input
              id="hotelCosts"
              name="hotelCosts"
              type="text"
              value={formData.hotelCosts}
              onChange={(e) => updateField('hotelCosts', e.target.value)}
            />
          </Label>
        </StyledInputRow>
      ) : null}

      {data.showParkingCosts === true ? (
        <StyledInputRow>
          <Label
            htmlFor="parkCosts"
            text="Parkgebühren in EUR"
            required={isFieldRequired('parkCosts')}
            valid={isFieldValid('parkCosts')}
          >
            <input
              id="parkCosts"
              name="parkCosts"
              type="text"
              value={formData.parkCosts}
              onChange={(e) => updateField('parkCosts', e.target.value)}
            />
          </Label>
        </StyledInputRow>
      ) : null}

      {data.showCateringCosts === true ? (
        <StyledInputRow>
          <Label
            htmlFor="cateringCosts"
            text="Verpflegung in EUR"
            required={isFieldRequired('cateringCosts')}
            valid={isFieldValid('cateringCosts')}
          >
            <input
              id="cateringCosts"
              name="cateringCosts"
              type="text"
              value={formData.cateringCosts}
              onChange={(e) => updateField('cateringCosts', e.target.value)}
            />
          </Label>
        </StyledInputRow>
      ) : null}

      {data.showPostCosts === true ? (
        <StyledInputRow>
          <Label
            htmlFor="postCosts"
            text="Porto in EUR"
            required={isFieldRequired('postCosts')}
            valid={isFieldValid('postCosts')}
          >
            <input
              id="postCosts"
              name="postCosts"
              type="text"
              value={formData.postCosts}
              onChange={(e) => updateField('postCosts', e.target.value)}
            />
          </Label>
        </StyledInputRow>
      ) : null}

      <StyledFormHeadline>
        <Text size="lg" bold lSpacing="3px" uppercase>
          Anmerkung
        </Text>
        <div />
      </StyledFormHeadline>
      <StyledInputRow>
        <StyledTextArea
          id="note"
          name="note"
          rows="6"
          value={formData.note}
          onChange={(e) => updateField('note', e.target.value)}
        />
      </StyledInputRow>

      <StyledFormHeadline>
        <Text size="lg" bold lSpacing="3px" uppercase>
          Anhänge
        </Text>
        <div />
        <br />
        <Text size="xs">
          Beigefügte Anhänge werden nur an uns weitergeleitet, wenn Sie das Formular an uns
          versenden
        </Text>
      </StyledFormHeadline>
      <Box>
        <UploadInput
          onFileChange={(files) => {
            setUploadFiles(files);
          }}
        />
      </Box>

      <StyledButtonRow>
        <Button
          disabled={!isValid}
          background="scooter"
          text="Versenden"
          color="white"
          hoverType="opacity"
          onClick={() => handleSubmit(submitMail)}
        />
        <Button
          disabled={!isValid}
          text="Herunterladen"
          onClick={() => handleSubmit(submitPreview)}
        />
      </StyledButtonRow>
    </>
  );
}

BillingForm.propTypes = {
  onSubmit: PropTypes.func,
  data: PropTypes.object
};

BillingForm.defaultProps = {
  onSubmit: () => false,
  data: {}
};

export default BillingForm;
