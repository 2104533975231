import { Box, Footer, Header, Text } from 'components';
import React from 'react';
import styled from 'styled-components';

const StyledContent = styled(Box)`
  min-height: calc(100vh - 220px);
`;

function Home() {
  return (
    <Box>
      <Header />
      <StyledContent>
        <Box marginY={16}>
          <Text align="center" size="xl" color="tuna" bold>
            GQmed Login Bereich
          </Text>
          <br />
          <Text align="center" size="sm" color="tuna">
            Zukunftig entsteht hier ein Bereich für Dokumente und Aufgaben rund um den Ablauf Ihrer
            Kurse.
          </Text>
        </Box>
      </StyledContent>
      <Footer />
    </Box>
  );
}

export default Home;
