import { ReactComponent as Logo } from 'assets/images/logo.svg';
import React from 'react';
import styled from 'styled-components';
import Box from './Box';
import Link from './Link';

const StyledFooter = styled(Box)`
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: space-between;
  align-items: center;
  align-conent: center;
  background: ${(props) => props.theme.colors.black};
  padding: 5px 20px;
`;

const StyledLogo = styled(Box)`
  svg {
    height: 90px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
    svg {
      height: 100px;
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoints.large}) {
    svg {
      height: 100px;
    }
  }
`;

function Footer() {
  return (
    <StyledFooter>
      <StyledLogo style={{ postion: 'relative', top: '3px' }}>
        <Logo />
      </StyledLogo>
      <Box display="flex" alignItems="center" direction="row" gap="10">
        <Link href="https://gqmed.de/s/datenschutz" color="white" target="blank">
          Datenschutz
        </Link>
        <Link href="https://gqmed.de/s/impressum" color="white" target="blank">
          Impressum
        </Link>
        <Link href="https://gqmed.de/kontakt" color="white" target="blank">
          Kontakt
        </Link>
      </Box>
    </StyledFooter>
  );
}

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
