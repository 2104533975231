import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  *,
  *:before,
  *:after {
      box-sizing: border-box;
  }

  html {
    line-height: inherit;
    -ms-text-size-adjust: inherit;
    -webkit-text-size-adjust: inherit;
  }

  body {
    margin: 0;
    padding: 0;
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    font-family: 'Raleway';
    font-weight: 400;
  }

  html,
  body {
    background-color: #fff;
    height: 100%;
    font-family: 'Raleway';
    font-weight: 400;
  }

  :focus,
  :active {
    outline: 0 !important;
  }

  #root {
    width: 100%;
    height: 100%;
  }

  ol, ul {
    margin: 0;
    padding: 0;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }
`;

export default GlobalStyle;
