import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Box from './Box';

const StyledLoadingBackground = styled(Box)`
  background-color: ${(props) => props.theme.colors.gallery};
  opacity: ${(props) => (props.translucent ? 0.7 : 1)};
  z-index: 100;
`;

const StyledLoading = styled(Box)`
  cursor: default;
  z-index: 101;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  * {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }

  .lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid ${(props) => props.theme.colors.scooter};
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${(props) => props.theme.colors.scooter} transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

function Loading({ translucent, show, fullOverlay }) {
  if (show) {
    return (
      <>
        <StyledLoadingBackground
          display="flex"
          width="100%"
          height="100%"
          alignItems="center"
          justify="center"
          position={fullOverlay ? 'fixed' : 'absolute'}
          direction="column"
          z-index="990"
          left="0"
          top="0"
          translucent={translucent}
        />
        <StyledLoading
          display="flex"
          width="100%"
          height="100%"
          alignItems="center"
          justify="center"
          position={fullOverlay ? 'fixed' : 'absolute'}
          direction="column"
          z-index="999"
          left="0"
          top="0"
        >
          <div className="lds-ring">
            <div />
            <div />
            <div />
            <div />
          </div>
        </StyledLoading>
      </>
    );
  }

  return null;
}

Loading.propTypes = {
  translucent: PropTypes.bool,
  fullOverlay: PropTypes.bool,
  show: PropTypes.bool.isRequired
};

Loading.defaultProps = {
  translucent: true,
  fullOverlay: true
};

export default Loading;
