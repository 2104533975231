import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import styled from 'styled-components/macro';
import CreateBill from 'views/CreateBill';
import Home from 'views/Home';
import NoMatch from 'views/NoMatch';

const StyledMain = styled.div`
  display: flex;
  min-height: 100vh;
`;

const StyledContent = styled.div`
  width: 100%;
`;

const AppRoutes = () => (
  <BrowserRouter>
    <StyledMain>
      <StyledContent>
        <Routes>
          <Route index element={<Home />} />
          <Route path="/abrechnung/:hash" element={<CreateBill />} />
          <Route path="*" element={<NoMatch />} />
        </Routes>
      </StyledContent>
    </StyledMain>
  </BrowserRouter>
);

AppRoutes.propTypes = {};

export default AppRoutes;
