import { Alert, Box, Footer, Header, InfoBox, Loading, Text } from 'components';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { API_URL } from 'utils/api';
import { correctDecimalValues } from 'utils/form';
import BillingForm from './BillingForm';

const StyledContent = styled(Box)`
  min-height: calc(100vh - 220px);
`;

const StyledForm = styled(Box)`
  width: 95%;
  max-width: 1024px;
  margin: 0 auto;
  padding: 40px;
`;

const defaultData = {
  billingDate: new Date(),
  city: '',
  email: '',
  firstname: '',
  gender: '',
  iban: '',
  title: '',
  lastname: '',
  street: '',
  zip: '',
  units: '',
  kilometer: '',
  hotelCosts: '',
  postCosts: '',
  cateringCosts: '',
  parkCosts: '',
  note: ''
};

function CreateBill() {
  const [loading, setLoading] = useState(true);
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [billingData, setBillingData] = useState(defaultData);
  const { hash } = useParams();

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      const url = `${API_URL}/academic/${hash}`;
      try {
        const response = await fetch(url, {
          mode: 'cors',
          method: 'GET'
        });

        const data = await response.json();

        const billingDataUpdate = { ...defaultData, ...data };

        setBillingData(billingDataUpdate);
        setLoading(false);
      } catch (e) {
        console.log(e);
      }
    };

    load();
  }, [hash]);

  const postData = async (data, files, type) => {
    const formData = new FormData();
    const keys = Object.keys(data);

    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i];
      const value = data[key];
      if (value !== undefined && value !== '') {
        if (key === 'billingDate') {
          const billingDate = new Date(data[key]);
          const dateStr = format(billingDate, 'dd.MM.y');

          formData.append(key, dateStr);
        } else {
          formData.append(key, data[key]);
        }
      }
    }

    if (type === 'mail') {
      for (let i = 0; i < files.length; i += 1) {
        const file = files[i];
        formData.append('files[]', file, file.name);
      }
    }

    formData.append('action', type);

    const url = `${API_URL}/academic/${hash}`;

    // Default options are marked with *
    const response = await fetch(url, {
      method: 'POST',
      body: formData
    });

    return response.json(); // parses JSON response into native JavaScript objects
  };

  const handleSubmit = async ({ data, files, type }) => {
    setLoading(true);

    const formData = correctDecimalValues(data);

    try {
      // update billing entry
      const response = await postData(formData, files, type);

      if (response && response.status && response.status === 'ok') {
        if (type === 'preview') {
          const pdfUrl = `${API_URL}/pdf/academic/${hash}`;
          window.open(pdfUrl, '_blank').focus();
          setLoading(false);
        } else {
          setLoading(false);
          setShowSuccess(true);
        }
      } else {
        setLoading(false);
        setShowError(true);
      }
    } catch (e) {
      setLoading(false);
      setShowError(true);
    }
  };

  return (
    <Box>
      <Header title="Abrechnungsformular" />
      <StyledContent>
        <StyledForm>
          <InfoBox text="Dies ist Ihr persönliches Abrechnungsformular. Bitte füllen Sie alle notwendigen Felder aus, um die Abrechnung zu erstellen. Sie können die pdf-Datei danach direkt an uns senden. Dabei erhalten Sie gleichzeitig eine Kopie an Ihre E-Mail-Adresse. Alternativ können Sie das Dokument auch herunterladen." />

          <Box marginY={8}>
            {billingData.courseTitle ? (
              <>
                <Text
                  align="center"
                  size="xl"
                  color="tuna"
                  bold
                >{`${billingData.courseTitle}`}</Text>
                <Text align="center" size="xl" bold>{`${billingData.courseDateRange}`}</Text>
              </>
            ) : null}
          </Box>

          <BillingForm onSubmit={handleSubmit} data={billingData} />
        </StyledForm>
      </StyledContent>
      <Footer />
      <Loading show={loading} />

      <Alert
        show={showError}
        title="Fehler"
        message="Es ist ein Fehler aufgetreten, bitte versuchen Sie es später erneut. Falls der Fehler erneut auftreten sollte kontaktieren Sie uns bitte."
        btnAction={() => setShowError(false)}
        btnColor="red"
        btnBackground="gallery"
        btnBorderColor="red"
      />

      <Alert
        show={showSuccess}
        title="Danke"
        btnColor="scooter"
        message="Wir haben Ihre Abrechnung als Mail erhalten und senden Ihnen eine Kopie an die von Ihnen eingetragene Mailadresse."
        btnAction={() => setShowSuccess(false)}
      />
    </Box>
  );
}

export default CreateBill;
