import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import Box from './Box';
import Text from './Text';

const StyledLabel = styled.label`
  display: ${(props) => props.display};
  font-size: 0.875rem;
  font-weight: ${(props) => (props.regular ? 300 : 600)};
  margin-bottom: ${(props) => (props.noMarginBottom ? '0px' : '3px')};
  ${(props) =>
    props.valid === false &&
    css`
      .label span {
        color: ${(props) => props.theme.colors.red};
      }

      input {
        border: 1px solid ${(props) => props.theme.colors.red} !important;
        color: ${(props) => props.theme.colors.red};
      }

      & > select {
        border: 1px solid ${(props) => props.theme.colors.red} !important;
        color: ${(props) => props.theme.colors.red};
      }
    `}
`;

function Label({
  required,
  valid,
  text,
  children,
  htmlFor,
  display,
  regular,
  noMarginBottom,
  color
}) {
  return (
    <StyledLabel
      color={color}
      display={display}
      htmlFor={htmlFor}
      regular={regular}
      required={required}
      valid={valid}
      noMarginBottom={noMarginBottom}
      className={`${!valid ? 'with-error' : ''}`}
    >
      <>
        <Box display="flex" direction="row" className="label">
          <Text lSpacing=".8" lHeight="30px">
            {text}
          </Text>
          {required ? (
            <Text size="2xl" color="blue" inline marginLeft={1}>
              *
            </Text>
          ) : null}
        </Box>
        {children}
      </>
    </StyledLabel>
  );
}

Label.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  htmlFor: PropTypes.string.isRequired,
  display: PropTypes.string,
  regular: PropTypes.bool,
  required: PropTypes.bool,
  valid: PropTypes.bool,
  noMarginBottom: PropTypes.bool,
  color: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string])
};

Label.defaultProps = {
  display: 'block',
  regular: false,
  color: 'black2',
  noMarginBottom: false,
  required: false,
  valid: null,
  text: ''
};

export default Label;
