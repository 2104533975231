import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { faRemove as RemoveIcon } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Box from './Box';
import Text from './Text';

const StyledWrapper = styled(Box)`
  display: block;
  width: 100%;
  height: 100%;
`;

const StyledUploadWrapper = styled(Box)`
  display: flex;
  position: relative;
  height: 30px;
  width: 100%;
  align-content: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid #1d1d1d;

  &:hover {
    border: 1px solid #b7b7b7;
  }

  div {
    cursor: pointer;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    position: absolute;
    pointer-events: none;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 10;
  }

  input {
    position: absolute;
    border: 1px solid red;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
`;

const StyledFileList = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const StyledFileListItem = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;

  button {
    border: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
    font-size: 18px;
    cursor: pointer;

    &:hover {
      opacity: 0.6;
    }
  }
`;

function UploadInput({ onFileChange }) {
  const [uploadFiles, setUploadFiles] = useState([]);
  const [showError, setShowError] = useState(false);

  const onChange = (event) => {
    setShowError(false);
    const { files } = event.target;
    const filesToAdd = [];

    // max 3MB
    const maxSize = 3 * 1024 * 1024;

    for (let i = 0; i < files.length; i += 1) {
      const file = event.target.files[i];
      const { size } = file;

      if (file && size && size <= maxSize) {
        filesToAdd.push(file);
      } else {
        setShowError(true);
      }
    }

    const filesUpdate = [...uploadFiles, ...filesToAdd];

    setUploadFiles(filesUpdate);
    onFileChange(filesUpdate);
  };

  const removeFileFromList = (file) => {
    const { name } = file;
    const filesUpdate = uploadFiles.filter((file) => file.name !== name);

    setUploadFiles(filesUpdate);
    onFileChange(filesUpdate);
  };

  return (
    <StyledWrapper>
      <StyledUploadWrapper>
        <Box>
          <Text size="sm">Hier klicken, um Anhänge anzufügen</Text>
        </Box>
        <input
          title=""
          type="file"
          id="attachments"
          name="attachments"
          accept=".jpg, .jpeg, .png, .pdf"
          multiple
          onInput={onChange}
          onClick={(event) => {
            // @TODO fix it
            // eslint-disable-next-line no-param-reassign
            event.target.value = null;
          }}
        />
      </StyledUploadWrapper>

      {showError ? (
        <Box display="flex" justify="center" marginY={2}>
          <Text size="xs" color="red" center>
            Erlaubt sind nur PDF und Bilddateien, kleiner als 3mb
          </Text>
        </Box>
      ) : null}

      <StyledFileList marginY={8}>
        {uploadFiles.map((file) => (
          <StyledFileListItem key={`${file.name}`}>
            <Text>{file.name}</Text>
            <button type="button" onClick={() => removeFileFromList(file)}>
              <FontAwesomeIcon icon={RemoveIcon} size="lg" color="#3da5d9" />
            </button>
          </StyledFileListItem>
        ))}
      </StyledFileList>
    </StyledWrapper>
  );
}

UploadInput.propTypes = {
  onFileChange: PropTypes.func
};

UploadInput.defaultProps = {
  onFileChange: () => false
};

export default UploadInput;
