import { useEffect, useState } from 'react';

const VALIDATION_MODE = {
  always: 'always',
  onSubmit: 'submit'
};

function useForm(config) {
  const [formConfig, setFormConfig] = useState({});
  const [formData, setFormData] = useState(
    config.defaultData !== undefined ? config.defaultData : {}
  );
  const [isValid, setIsValid] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);

  useEffect(() => {
    let performValidation = true;
    // validate every field of formData using isFieldValid
    let overallValid = true;

    if (config && config.validation === VALIDATION_MODE.onSubmit && isSubmitted === false) {
      performValidation = false;
    }

    if (performValidation) {
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(formData)) {
        const fieldValid = isFieldValid(key);

        overallValid = overallValid && fieldValid;
      }
    }

    setIsValid(overallValid);
  }, [formData, formConfig]);

  const updateData = (data) => {
    setFormData(data);
  };

  const updateConfig = (configUpdate) => {
    setFormConfig(configUpdate);
  };

  const updateField = (key, value) => {
    const update = { ...formData };
    update[key] = value;

    setFormData(update);
    setHasChanged(true);
  };

  const isFieldValid = (key, hasSubmit) => {
    let performValidation = true;
    let valid = true;
    const submitted = hasSubmit !== undefined ? hasSubmit : isSubmitted;

    if (config && config.validation === VALIDATION_MODE.onSubmit && submitted === false) {
      performValidation = false;
    }

    if (performValidation) {
      const keyConfig = formConfig[key];

      if (keyConfig) {
        const { required, validate } = keyConfig;
        const value = formData[key];
        const hasValue = value !== undefined && value !== null && value !== '';

        if (required === true) {
          valid = hasValue;
        }

        // use function configured for key
        // validate with given validation if a value is set
        if (hasValue && validate !== undefined) {
          valid = validate(value);
        }
      }
    }

    return valid;
  };

  const isFieldRequired = (key) => {
    let required = false;
    const keyConfig = formConfig[key];

    if (keyConfig) {
      required = keyConfig.required;
    }

    return required;
  };

  const handleSubmit = (callback) => {
    const submitted = true;
    let overallValid = true;

    // validate every field of formData using isFieldValid
    // eslint-disable-next-line no-restricted-syntax
    for (const [key] of Object.entries(formData)) {
      const validField = isFieldValid(key, submitted);
      overallValid = overallValid && validField;
    }

    setIsSubmitted(submitted);
    setIsValid(overallValid);

    callback({ submitData: formData, submitValid: overallValid });
  };

  return {
    isValid,
    isSubmitted,
    hasChanged,
    formData,
    updateField,
    updateData,
    updateConfig,
    isFieldValid,
    isFieldRequired,
    handleSubmit
  };
}

export default useForm;
