import PropTypes from 'prop-types';
import React from 'react';
import { NavLink, Link as RouterLink } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';

const LinkStyles = css`
  color: ${(props) => props.theme.colors[props.color]};
  outline: none;
  display: ${(props) => (props.inline ? 'inline-block' : 'block')};
  user-select: ${(props) => (props.onClick ? 'none' : 'auto')};
  text-decoration: none;

  &.selected {
    font-weight: 600;
    color: ${(props) => props.theme.colors.green};
  }

  &:hover,
  &:focus {
    cursor: pointer;
    text-decoration: none;
  }
`;

const StyledLink = styled.a`
  ${LinkStyles}
`;

const StyledLinkRouter = styled(RouterLink)`
  ${LinkStyles}
`;

const StyledNavLinkRouter = styled(NavLink)`
  ${LinkStyles}
`;

function Link({
  children,
  routerLink,
  navLink,
  inline,
  target,
  href,
  onClick,
  isActiveCheck,
  ...rest
}) {
  const rel = target === 'blank' ? 'noopener noreferrer' : null;
  const linkTarget = target ? `_${target}` : null;

  const handleClick = (event) => {
    if (onClick) {
      onClick({ event });
    }
  };

  if (routerLink) {
    return (
      <StyledLinkRouter to={href} {...rest}>
        {children}
      </StyledLinkRouter>
    );
  }

  if (navLink) {
    return (
      <StyledNavLinkRouter
        exact
        to={href}
        activeClassName="selected"
        isActive={isActiveCheck !== null ? isActiveCheck : null}
        {...rest}
      >
        {children}
      </StyledNavLinkRouter>
    );
  }

  return (
    <StyledLink
      inline={inline}
      href={href}
      onClick={handleClick}
      rel={rel}
      target={linkTarget}
      {...rest}
    >
      {children}
    </StyledLink>
  );
}

Link.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  href: PropTypes.string,
  routerLink: PropTypes.bool,
  navLink: PropTypes.bool,
  inline: PropTypes.bool,
  onClick: PropTypes.func,
  isActiveCheck: PropTypes.func,
  target: PropTypes.oneOf([null, 'self', 'blank'])
};

Link.defaultProps = {
  href: '',
  color: 'white',
  routerLink: false,
  navLink: false,
  inline: false,
  onClick: null,
  target: 'self',
  isActiveCheck: null
};

export default Link;
