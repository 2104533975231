const NUMBER_PATTERN = /^[0-9]+?$/;
const FLOAT_PATTERN = /^[0-9]+((,|.)[0-9]{1,2})?$/;
const MAIL_PATTERN =
  // eslint-disable-next-line
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const validateNumber = (value, validationPattern) => {
  const numValue = parseFloat(value);
  let valid = false;

  const validationRegEx = RegExp(validationPattern);
  const validNumber = validationRegEx.test(value);

  if (
    validNumber &&
    numValue !== undefined &&
    numValue !== null &&
    !Number.isNaN(numValue) &&
    Number.isFinite(numValue)
  ) {
    valid = true;
  }

  return valid;
};

export const correctDecimalValues = (data) => {
  const keys = Object.keys(data);
  const updated = { ...data };
  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i];
    const value = data[key];

    const validationRegEx = RegExp(FLOAT_PATTERN);
    const isFloat = validationRegEx.test(value);

    if (isFloat) {
      // const fixed = parseFloat(value.replace(',','.'));
      const fixed = value.replace(',', '.');
      updated[key] = fixed;
    }
  }

  return updated;
};

const validateMail = (value) => {
  let valid = false;

  if (value !== undefined && value !== null && value !== '') {
    const regEx = RegExp(MAIL_PATTERN);
    valid = regEx.test(value);
  }

  return valid;
};

export const getBillingFormConfig = () => {
  const config = {
    billingDate: {
      required: true
    },
    firstname: {
      required: true
    },
    lastname: {
      required: true
    },
    email: {
      required: true,
      validate: (value) => validateMail(value)
    },
    gender: {
      required: true
    },
    street: {
      required: true
    },
    city: {
      required: true
    },
    zip: {
      required: true
    },
    title: {
      required: false
    },
    iban: {
      required: true
      /* @TODO IBAN PATTERN */
    },
    note: {
      required: false
    },
    units: {
      required: true,
      validate: (value) => validateNumber(value, FLOAT_PATTERN)
    },
    cateringCosts: {
      required: false,
      validate: (value) => validateNumber(value, FLOAT_PATTERN)
    },
    kilometer: {
      required: false,
      validate: (value) => validateNumber(value, FLOAT_PATTERN)
    },
    parkCosts: {
      required: false,
      validate: (value) => validateNumber(value, FLOAT_PATTERN)
    },
    hotelCosts: {
      required: false,
      validate: (value) => validateNumber(value, FLOAT_PATTERN)
    },
    postCosts: {
      required: false,
      validate: (value) => validateNumber(value, FLOAT_PATTERN)
    }
  };

  return config;
};
