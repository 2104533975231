import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import Text from './Text';

const pixelOrGivenValue = (value) => {
  if (value === 'auto') {
    return 'auto';
  }
  return typeof value === 'number' ? `${value}px` : value;
};

const StyledButton = styled.button`
  background-color: ${(props) =>
    props.disabled ? props.theme.colors.silver : props.theme.colors[props.background]};
  color: ${(props) => (props.disabled ? props.theme.colors.white : props.theme.colors.scooter)};
  display: ${(props) => (props.inline ? 'inline-block' : 'block')};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  border-radius: ${(props) => `${pixelOrGivenValue(props.borderRadius)}`};
  border: 2px solid
    ${(props) =>
      props.disabled ? props.theme.colors.silver : props.theme.colors[props.borderColor]};
  outline: 0;
  padding: ${(props) => (props.size === 'sm' ? '6px 12px' : '16px 89px')}; /* padding: 16px 89px; */
  position: relative;
  ${(props) => (!props.width && props.inline ? 'width: auto' : 'width: 100%')};
  ${(props) => props.width && `width: ${props.width}px`};
  ${(props) => props.height && `height: ${props.height}px`};
  min-width: ${(props) => (props.minWidth ? `${pixelOrGivenValue(props.minWidth)}` : '100px')};
  ${(props) => props.noShadow && `box-shadow: none`};
  ${(props) => props.noPadding && `padding: 0px`};

  transition: all 0.3s ease 0s;

  &:hover:not([disabled]) {
    ${(props) =>
      props.hoverType &&
      props.hoverType === 'opacity' &&
      css`
        opacity: 0.75;
      `};

    ${(props) =>
      props.hoverType &&
      props.hoverType === 'default' &&
      css`
        background-color: ${(props) =>
          props.disabled ? props.theme.colors.grey : props.theme.colors[props.color]};
        color: #fff;

        span {
          color: #fff;
        }
      `};
  }

  ${(props) =>
    props.hasIconAndText &&
    css`
      display: flex;
      align-items: center;

      span:first-child {
        margin-left: 10px;
      }

      span:nth-child(2) {
        width: 100%;
        display: inline-block;
        text-align: center;
      }
    `};
`;

function Button({ icon, iconColor, text, textSize, ...props }) {
  /* eslint-disable react/button-has-type */
  return (
    <StyledButton {...props}>
      {icon !== null && (
        <Text inline color={iconColor} size={textSize}>
          {icon}
        </Text>
      )}

      {text !== '' && (
        <Text
          color={props.disabled ? 'white' : props.color}
          align="center"
          overflow="normal"
          size={textSize}
        >
          {text}
        </Text>
      )}
    </StyledButton>
  );
  /* eslint-enable react/button-has-type */
}

Button.propTypes = {
  disabled: PropTypes.bool,
  inline: PropTypes.bool,
  name: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.string,
  textSize: PropTypes.string,
  width: PropTypes.number,
  minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  size: PropTypes.string,
  background: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.any,
  iconColor: PropTypes.string,
  borderRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  noShadow: PropTypes.bool,
  hoverType: PropTypes.string,
  borderColor: PropTypes.string
};

Button.defaultProps = {
  textSize: 'lg',
  width: null,
  minWidth: 'auto',
  disabled: false,
  inline: false,
  type: 'button',
  size: 'base',
  name: '',
  background: 'white',
  color: 'scooter',
  text: '',
  onClick: () => {},
  icon: null,
  iconColor: '#fff',
  borderRadius: 0,
  noShadow: false,
  hoverType: 'default',
  borderColor: 'scooter'
};

export default Button;
